import React from "react"
import { Box, chakra, Container, Heading, Link, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import Seo from "../components/seo"

// https://github.com/gatsbyjs/gatsby/issues/5329
// unnecessary with gatsby-plugin-netlify
// const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Container maxW="7xl" mt="100px">
      <Heading as="h1">NOT FOUND</Heading>
      <Text>You just hit a route that doesn&#39;t exist...</Text>
      <Text>
        <Link as={GatsbyLink} to="/">
          Click here to head back home
        </Link>
      </Text>
      <Box w="100%" h="0" pb="44%" pos="relative" mt="10">
        <Box
          as="iframe"
          title="ET Gone Home GIF"
          src="https://giphy.com/embed/14jRWmyHsokyOY"
          width="100%"
          height="100%"
          frameBorder="0"
          class="giphy-embed"
          allowFullScreen
          pos="absolute"
        />
        <chakra.a
          pos="absolute"
          bottom="-20px"
          right="5px"
          fontSize="xx-small"
          href="https://giphy.com/gifs/movie-head-kims-14jRWmyHsokyOY"
        >
          via GIPHY
        </chakra.a>
      </Box>
    </Container>
  </>
)

export default NotFoundPage
